.AppointmentForm {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  flex-direction: column;

  & > * {
    margin-bottom: 2rem !important;
  }

  &__Schedule-Form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__weekday {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      margin-bottom: .5rem;

      &__checkbox {
        .MuiSvgIcon-root {
          fill: var(--bs-body-color);
        }
      }

      & > input {
        flex: 1;
      }

      &.disabled {
        & > span {
          opacity: .5;
        }
      }

      & > span {
        width: 10%;
        text-align: center;
      }
    }
  }
}