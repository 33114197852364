#collapse-header-form-template {
    .MuiAccordionSummary-content {
      display: flex;
      align-items: center;
      align-content: center;
      margin: 0;
      .form-label {
        margin: 0;
      }
    }  
}
#template-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  .template-container {
    display: flex;
    flex-direction: column;
    .form-template {
      position: relative;
      overflow: hidden;
      margin-bottom: 5px;
      width: 150px;
      height: 150px;
    
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: transform 0.3s ease, filter 0.3s ease;
      }
    
      .figure-caption {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        width: 100%;
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s, opacity 0.3s ease;
        cursor: pointer;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.5);
        padding: 5px;
      }
    
      &:hover {
        img {
          transform: scale(1.1);
          filter: blur(5px);
        }
    
        .figure-caption {
          visibility: visible;
          opacity: 1;
        }
      }
    }
  }
}

.star-button > .svg-icon {
  color: #ffc107
}


