@import "./Variables.scss";
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-ext-100.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-100.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-ext-300.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-300.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-ext-400.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-400.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-ext-700.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-700.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 900;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-ext-900.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: ≈;
  font-weight: 900;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-italic-latin-900.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-ext-100.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 100;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-100.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-ext-300.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-300.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-ext-400.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-400.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-ext-700.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-700.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-ext-900.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url("./assets/fonts/latoNew/lato-normal-latin-900.woff2") format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC,
    U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato-Black";
  src: url("./assets/fonts/Lato/Lato-Black.ttf");
}

@font-face {
  font-family: "Lato-BlackItalic";
  src: url("./assets/fonts/Lato/Lato-BlackItalic.ttf");
}

@font-face {
  font-family: "bold";
  src: url("./assets/fonts/Lato/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato-BoldItalic";
  src: url("./assets/fonts/Lato/Lato-BoldItalic.ttf");
}

@font-face {
  font-family: "Lato-Italic";
  src: url("./assets/fonts/Lato/Lato-Italic.ttf");
}

@font-face {
  font-family: "light";
  src: url("./assets/fonts/Lato/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato-LightItalic";
  src: url("./assets/fonts/Lato/Lato-LightItalic.ttf");
}

@font-face {
  font-family: "normal";
  src: url("./assets/fonts/Lato/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato-Thin";
  src: url("./assets/fonts/Lato/Lato-Thin.ttf");
}

@font-face {
  font-family: "Lato-ThinItalic";
  src: url("./assets/fonts/Lato/Lato-ThinItalic.ttf");
}

@font-face {
  font-family: "Lato-ThinItalic";
  src: url("./assets/fonts/Lato/Lato-ThinItalic.ttf");
}

@font-face {
  font-family: "Happy Icons";
  src: url("./assets/fonts/Lato/happy-icons.woff2");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


