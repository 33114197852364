 @import "src/Variables.scss";

.AppointmentPicker {

  &.MuiPickersLayout-root {
    background: $backgroundColor;
  }

}

 .MuiTypography-root, .MuiDayCalendar-weekDayLabel, .MuiPickersDay-root {
   color: $secondColor;
   font-size: 16px;
 }

 .MuiButtonBase-root.MuiPickersDay-root.Mui-disabled {
   color: $secondColor !important;
   opacity: .7;
 }

 .MuiButtonBase-root.MuiPickersDay-root {
   color: $secondColor;
   font-size: 12px;

   &.Mui-selected {
     background: $buttonBackground;
     color: $buttonColor;

     &:focus {
       will-change: background-color;
       background-color: $buttonBackground;
     }
   }
 }

 .MuiButtonBase-root.MuiIconButton-root {
   background: $buttonBackground;

   &:hover {
     background: $buttonBackground;
   }

   &.Mui-disabled {
     opacity: .7;
   }
 }


 .MuiTypography-root.MuiTypography-caption.MuiDayCalendar-weekDayLabel {
   color: $secondColor;
 }