@import "../../../Variables.scss";

.VForm {
  height: 100%;
  width: 100%;
  position: relative;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: $formInputColor;
  }

  &__bg-image {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    object-fit: cover;
    object-position: 25% 25%;
  }

  &__inner {
    min-height: 100%;
    background: $backgroundColor;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    overflow: hidden;

    &__headline {
      color: $formTextColorHeadline;
      font-size: 20px;
      font-weight: 300;
      font-family: Lato;
    }

    &__content {
      color: $formTextColorContent;
      font-size: 16px;
      font-family: light;
      line-height: 1.6em;
      margin-top: 1rem;
      overflow: hidden;
      word-wrap: break-word;
    }

    &.inactive {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: $primaryColor;
  }

  .MuiLinearProgress-colorPrimary {
    background-color: $backgroundColor;
  }

  &__Form-Wrapper {

    &.isCreator {
      width: 100%;
    }

    min-height: calc(100vh - 2.5rem);
    width: 50vw;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 1;

    @include sm {
      width: 95vw;
    }
  }

  &__Close-Button {

    &.MuiButtonBase-root {
      position: absolute;
      right: 1.2rem;
      top: 1rem;
      background: $buttonBackground;
      height: 1.45rem;
      width: 1.45rem;
      min-width: unset;
      border-radius: 20px;
      display: flex;
      justify-content: center;
      align-items: center;

      &:hover {
        background: $buttonBackground;
      }

      .MuiSvgIcon-root {
        fill: $buttonColor;
        height: 20px;
      }
    }
  }

  &__Action-Buttons {
    height: 2.5rem;
    box-sizing: border-box;
    width: 100%;
    padding: 0 12px;
    position: fixed;
    bottom: 2rem;
    display: flex;
    justify-content: space-between;
    z-index: 2;

    &__left, &__right {
      display: flex;
      align-items: center;
    }

    &__left {
      .EButton__primary {
        padding: 8px 18px;
        font-size: 20px;
        font-family: bold;
        height: 32px;

        .MuiSvgIcon-root {
          margin-left: 12px;
        }
      }
    }

    &__right {
      .EButton__primary {

        height: 32px;
        width: 2rem;
        min-width: unset;

        &:first-of-type {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        &:last-of-type {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    &__mid {
      &__checkbox {
        & .MuiSvgIcon-root {
          fill: $formInputColor;
        }

        & .MuiStack-root {
          & .MuiTypography-root {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

}