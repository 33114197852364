@import "./settings/_index.scss";

.NestedNav {

  &__nav-button {
    height: 5rem;
    border-bottom: 2px solid white;
    display: flex;
    align-items: center;
    padding: .5rem;
    margin: 0;
    cursor: pointer;

    &__go-to-page {
      flex: 1 1 10% !important;
      box-sizing: border-box;
    }

    &__order {
        flex: 1 1 20% !important;
    }

    &__page-title {
      flex: 1 1 60% !important;

      &__title {
        align-items: baseline !important;
        overflow-x: hidden;
        overflow-y: scroll;
        line-break: anywhere;
        margin-bottom: 0;
        height: 100%;
      }

      &::-webkit-scrollbar {

        display: none;
      }

    }

    & > * {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
    }

    &:first-of-type {
      margin-top: 1rem;
      border-top: 2px solid white;
    }
  }

  &__nav-wrapper {

    & > * {
      width: 100%;
    }

    &__action-buttons {

      display: flex;
      justify-content: center;
      margin-top: 1rem;

      .btn {
        width: 50%;
      }
    }
  }

  &__iframe {
    background: $dark-input-bg;
    border-color: $dark-input-border-color;
    color: rgba($dark-input-color, 0.5);
    border-radius: 25px;
    resize: none;
    padding: 1rem;
    position: relative;

    &__copy {
      display: none;
      border-radius: 25px;
      width: 100%;
      position: absolute;
      background: rgba(0,0,0,0.5);
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      color: $dark-input-color;
      font-weight: bold;
    }

    &__text {
      margin: 0;
    }

    &:hover  &__copy {
      display: flex;
      justify-content: center;
      align-items: center;
    }

  }
}