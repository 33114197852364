@import "src/Variables.scss";

.EMessage {

  font-size: 12px;
  text-align: left;
  width: 100%;

  &.error {
    color: $colorDanger;
  }

  &.success {
    color: $colorSuccess;
  }

}