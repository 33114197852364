/*
* VARIABLES
*/

:root {
  --primaryColor: #00ffff;
  --secondColor: rgba(255,255,255,1);
  --backgroundColor: rgba(0,0,0,1);
  --buttonColor: var(--backgroundColor);
  --buttonBackground: var(--primaryColor);
  --formTextColorHeadline: var(--primaryColor);
  --formTextColorContent: var(--primaryColor);
  --formInputColor: var(--formTextColorContent);
  --formInputColorComp: var(--formInputColor);
}

$primaryColor: var(--primaryColor);
$secondColor: var(--secondColor);
$backgroundColor: var(--backgroundColor);
$buttonColor: var(--buttonColor);
$buttonBackground: var(--buttonBackground);
$formTextColorHeadline: var(--formTextColorHeadline);
$formTextColorContent: var(--formTextColorContent);
$formInputColor: var(--formInputColor);
$formInputColorComp: var(--formInputColorComp);



/*
* BREAKPOINTS
*/

$sm: 768px;
$md: 992px;
$lg: 1280px;
$xl: 1320px;
$xxl: 1500px;


/*
* VIEWPORTS
*/


/*
* MIXINS
*/

@mixin sm {
  @media screen and (max-width: $sm - 1) {
    @content
  }
}

@mixin md {
  @media screen and (min-width: $sm) and (max-width: $md - 1) {
    @content
  }
}

@mixin lg {
  @media screen and (min-width: $md) and (max-width: $lg - 1) {
    @content
  }
}

@mixin xl {
  @media screen and (min-width: $lg) and (max-width: $xl - 1) {
    @content
  }
}

@mixin xxl {
  @media screen and (min-width: $xl) and (max-width: $xxl - 1) {
    @content
  }
}

@mixin big {
  @media screen and (min-width: $xxl) {
    @content
  }
}

@mixin mobile {
  @include sm { @content }
  @include md { @content }
}
