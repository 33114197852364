@import "src/Variables";

.EButton {

  &__primary.MuiButtonBase-root {
    border: 1px solid $buttonBackground;
    background-color: $buttonBackground;
    text-transform: none;
    border-radius: 5px;
    color: $buttonColor;
    font-weight: bold;

    &:hover {
      background-color:  $buttonBackground;
    }
  }

  &__light.MuiButtonBase-root {
    border: 1px solid $primaryColor;
    text-transform: none;
    border-radius: 5px;

    &:hover {
      background: rgba($primaryColor, 0.05);
    }
  }

  &__text.MuiButtonBase-root {
    border: none !important;
    text-transform: none;
    border-radius: 5px;
    background: none !important;
    width: 100%;

    &:hover {
      background: rgba($primaryColor, 0.05);
    }
  }

  &__white.MuiButtonBase-root {
    border: 1px solid gray;
    text-transform: none;
    border-radius: 5px;
    color: black;

    &:hover {
      background: unset;
      border: 1px solid gray;
    }
  }

  &__primary.MuiButton-root.MuiButtonBase-root.Mui-disabled {
    background: $backgroundColor !important;
    color: $primaryColor !important;
  }
}