.DraggableItem {
  flex-shrink: 1;
  cursor: grab;
  border: 2px dashed #ccc;
  margin-left: 8px;
  margin-right: 8px;
  padding: 20px;
  margin-bottom: 4px;
  font-size: 18px;

  &:hover {
    background: rgba(255,255,255,0.1);
  }
}