:root {
  --primaryColor: rgba(0, 255, 255,1);
  --secondColor: #fff;
  --backgroundColor: rgba(0,0,0,1);
  --colorActive: rgb(0 0 0 / 88%);
  --colorInActive: #578390;
  --colorheaderAnimation: #578390;
  --bannerTextColor: #fff;
  --bannerColor: red;
  --buttonColor: var(--backgroundColor);
  --buttonBackground: var(--primaryColor);
  --testSelectionDescriptionColor: rgb(0, 255, 255);
  --priceFieldColor: rgba(0, 0, 0, 0.5);
  --priceFieldTextColor: #fff;
  --colorDanger: #660701;
  --colorSuccess: #0c6402;
  --colorWarning: #ff8e02;
  --formButtonColor: rgb(0, 255, 255);
  --formButtonTextColor: #000;
  --formTextColorHeadline: var(--primaryColor);
  --formTextColorContent: var(--primaryColor);
  --formTextColorError: red;
  --cardButtonColor: rgb(0, 255, 255);
  --cardButtonTextColor: #000;
  --cardTextColorHeadline: rgb(0, 255, 255);
  --cardTextColorContent: #fff;
  --cardTextColorError: red;
  --cardBackgroundColor: #000;
  --bannerBackgroundInactive: rgb(0, 0, 0, 0);
  --bannerTextColorInactive: rgb(0, 0, 0, 0);
  --footerButtonTextColor: var(--primaryColor);
  --footerButtonBGColor: var(--buttonColor);
  --cardFinishButtonColor: var(--primaryColor);
  --cardFinishButtonTextColor: #000;
  --formInputColor: var(--formTextColorContent);
  --headlineColorActive: $secondColor;
  --headlineColorInActive: $secondColor;
  --formInputColorComp: var(--formInputColor);
}

$primaryColor: var(--primaryColor);
$secondColor: var(--secondColor);
$backgroundColor: var(--backgroundColor);
$colorActive: var(--colorActive);
$colorInActive: var(--colorInActive);
$colorheaderAnimation: var(--colorheaderAnimation);
$bannerColor: var(--bannerColor);
$bannerTextColor: var(--bannerTextColor);
$buttonColor: var(--buttonColor);
$buttonBackground: var(--buttonBackground);
$testSelectionDescriptionColor: var(--testSelectionDescriptionColor);
$priceFieldColor: var(--priceFieldColor);
$priceFieldTextColor: var(--priceFieldTextColor);
$colorDanger: var(--colorDanger);
$colorSuccess: var(--colorSuccess);
$colorWarning: var(--colorWarning);
$formButtonColor: var(--formButtonColor);
$formButtonTextColor: var(--formButtonTextColor);
$formTextColorHeadline: var(--formTextColorHeadline);
$formTextColorContent: var(--formTextColorContent);
$formTextColorError: var(--formTextColorError);
$cardButtonColor: var(--cardButtonColor);
$cardButtonTextColor: var(--cardButtonTextColor);
$cardTextColorHeadline: var(--cardTextColorHeadline);
$cardTextColorContent: var(--cardTextColorContent);
$cardTextColorError: var(--cardTextColorError);
$cardBackgroundColor: var(--cardBackgroundColor);
$cardFinishButtonColor: var(--cardFinishButtonColor);
$bannerBackgroundInactive: var(--bannerBackgroundInactive);
$bannerTextColorInactive: var(--bannerTextColorInactive);
$cardFinishButtonTextColor: var(--cardFinishButtonTextColor);
$formInputColor: var(--formInputColor);
$footerButtonBGColor: var(--footerButtonBGColor);
$footerButtonTextColor: var(--footerButtonTextColor);
$headlineColorActive: var(--headlineColorActive);
$headlineColorInActive: var(--headlineColorInActive);
$formInputColorComp: var(--formInputColorComp);