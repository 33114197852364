.react-flow {

  background: var(--bs-body-bg);

  &__handle {
    min-height: 10px;
    min-width: 10px;
  }

  &__info {
    position: absolute;
    bottom: -10rem;
    border: 1px solid var(--bs-body-color);
    padding: .5rem;
    overflow: hidden;
    z-index: 2;
    background: var(--bs-body-bg);
    top: 100%;
    left: 0;
    width: 120px;
    color: var(--bs-body-color);

    & > * {
      color: var(--bs-body-color);
    }
  }

  &__CustomFlow {
    background: var(--bs-body-bg);
  }

  &__node-PageNode {

    -webkit-box-shadow: 1px 6px 15px 5px #000000;
    box-shadow: 1px 6px 15px 5px #000000;

    & > * {
      color: black;
    }
  }
}