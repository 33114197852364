.ImageGallery {
  padding: .25rem;
  width: 100%;

  &__gallery {

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    &__img-wrapper {
      display: flex;
      padding: 0.1rem;
      box-sizing: border-box;
      align-items: center;
      justify-content: center;
      margin: .25rem;
      position: relative;

      &.selected {
        border: 2px solid #4d69fa;
      }

      &__check {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        box-sizing: border-box;
        padding: .1rem;
        background: #4d69fa;
        border-radius: 25px;
      }

      & > img {
        width: 50px;
        height: 50px;

        object-fit: cover;
      }
    }

  }
}