
.DefaultFormTable {


  &__button {
    display: flex;
    justify-content: space-evenly;
    margin: 2rem 0rem;
    flex-direction: row;
    padding: 1rem;
    cursor: pointer;

    &:hover {
      background-color: rgba(73, 80, 87, 0.8);
    }

    &__color {
      height: 3rem;
      width: 3rem;
    }

  }
}