.FieldForm {
  .MuiCheckbox-root {
    .MuiSvgIcon-root {
      color: var(--bs-body-color);
    }
  }

  .MuiTypography-root {
    color: var(--bs-body-color)
  }

}