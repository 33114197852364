//
//	Timeline
//

.timeline {
	position: relative;

	&::before {
		position: absolute;
		z-index: 1; // stylelint-disable-line scale-unlimited/declaration-strict-value
		top: 0.5rem;
		bottom: 0;
		left: calc(#{$timeline-label-width} + 0.5rem + 4px); // stylelint-disable-line meowtec/no-px
		width: $timeline-line-width;
		background-color: $timeline-line-color;
		border-radius: 1rem;
		content: '';
	}
}

.timeline-item {
	display: flex;

	&:not(:last-child) {
		padding-bottom: $timeline-item-space;
	}

	&:last-child {
		padding-bottom: $timeline-item-space * 0.5;
	}

	.timeline-label {
		width: 100%;
		max-width: $timeline-label-width;
	}

	&.created {
		.timeline-badge {
			svg {
				fill: var(--bs-green);
			}
		}
	}

	&.edited {
		.timeline-badge {
			svg {
				fill: var(--bs-yellow);
			}
		}
	}

	&.deleted {
		.timeline-badge {
			svg {
				fill: var(--bs-red);
			}
		}
	}

	.timeline-badge {
		position: relative;
		z-index: 2; // stylelint-disable-line scale-unlimited/declaration-strict-value
		width: 100%;
		max-width: 1rem;
		text-align: center;

		> * {
			box-sizing: content-box;
			border: 4px solid var(--bs-card-bg); // stylelint-disable-line meowtec/no-px
			background-color: var(--bs-card-bg);
			border-radius: 1rem;
		}
	}
}
